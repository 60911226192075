@media screen and ( max-width: $tablet - 1px ) {

  .catering-lunchbox-page__form {
    font-size: 0.9rem;

    .control, button, .number-input input, .number-input button {
      font-size: 0.8rem; } }

  .catering-lunchbox-page__cart-button {
    margin-top: 0.4em; } }
