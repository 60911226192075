.modal-container {
  background-color: change-color($dark, $alpha: 0.75);

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  z-index: 9999999999;

  &.modal-container--closed {
    display: none; } }

.modal-container__modal {
  background-color: $light;
  padding: 1rem;
  margin: 4rem 1rem;
  max-width: calc(100vw - 2rem);

  @media screen and ( min-width: $tablet ) {
    margin: 4rem;
    max-width: calc(100vw - 8rem); }

  border-radius: $radius;
  border: 1px solid $border;

  overflow-y: auto; }
