.order-modal {
  @include tablet {
    width: 30rem; } }

.order-modal__payment-form {
  @include tablet {
    width: 30rem; }
  margin: 0 auto 1em;

  & iframe, & > div {
    width: 100%;
    height: 100%; } }

.order-modal__payment-option,
.order-modal__thank-you-wrapper {
  margin: 1.5rem 0; }

.order-modal__thank-you-message {
  font-size: 2rem;
  text-align: center; }

.order-modal__thank-you-icon {
  margin: 1rem 0; }

.order-modal__item {
  max-width: 300px;
  margin: 1rem auto;

  &:not(:last-child) {
    padding-bottom: 1rem;
    margin-bottom: 0;
    border-bottom: 1px solid $grey-lighter; } }

.order-modal__item-quantity {
  font-size: 3rem; }

.order-modal__item-description {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

.order-modal__item-name {
  font-size: 1.5rem;
  max-width: 50vw; }

.order-modal__item-price {
  font-size: 1.25rem;
  font-weight: bold; }

.order-modal__defer-message {
  font-size: 1.5rem;
  text-align: center;

  max-width: 30rem;

  & button {
    display: block;
    margin: 0.5rem auto; } }

.order-modal__email-input {
  margin-bottom: 1.5em; }

.order-modal__email-not-ready-cancel-button {
  margin-bottom: 1em; }
