// Bulma variables config
$body-size: 16px;
$family-sans-serif: 'Source Serif Pro', sans-serif;
$navbar-item-img-max-height: 3rem;
$navbar-box-shadow-color: #707070;
$navbar-height: 4rem;
$section-padding: 2.4rem 1.5rem;

$danger: #d1806f;
$warning: #efa23b;
$primary: #efa23b;

// Reverting 0.8.0 Bulma changes regarding form control sizes
$control-border-width: 1px !default;
$button-border-width: $control-border-width !default;
$control-height: 2.25em;
$control-padding-vertical: calc(.375em - #{$control-border-width});
$control-padding-horizontal: calc(.625em - #{$control-border-width});
$button-padding-vertical: calc(.375em - #{$button-border-width});
$button-padding-horizontal: .75em;
