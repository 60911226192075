.catering-item {
  margin-bottom: 1.75em;

  position: relative;

  &:not(:last-child)::after {
    position: absolute;
    content: '';
    left: 1rem;
    right: 1rem;
    bottom: 0;
    height: 1px;
    background-color: mix($dark, $light, $weight: 10%); } }

.catering-item__options {
  padding: 1em 0; }

.catering-item__option {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 1.5em;

  @media screen and (max-width: 768px) {
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 2rem; } }

.catering-item__name {
  font-size: 1.25em;
  font-weight: bold;
  margin-bottom: 0.25em; }

.catering-item__option-text-column {
  padding-right: 2em;

  @media screen and (max-width: 768px) {
    padding-right: .5em; } }

.catering-item__option-title {
  font-weight: bold; }

.catering-item__option-description {
  font-size: 0.75em; }

.catering-item__option-input-column {
  min-width: 8rem; }

.catering-item__option-input {
  display: flex;
  justify-content: space-between; }

.catering-item__option-number-input {
  flex-grow: 0;
  flex-shrink: 0;

  &, &:not(:last-child) { // damn you, specificity
    margin-bottom: 0; } }

.catering-item__option-input-spacer {
  flex: 0 0 .5em; }

.catering-item__option-price {
  font-weight: bold; }

.catering-item__option-price-indicator {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 15px; }
