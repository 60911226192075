.catering-navbar {
  position: relative;

  background-color: #f7f0e8;

  &::before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: -2px;
    height: 2px;
    background-color: #dbdbdb; }

  .navbar-item {
    @media screen and (max-width: 1142px) and (min-width: 1024px) {
      font-size: .8rem; } }


  .blipped-button__button {
    @media screen and (max-width: 1142px) and (min-width: 1024px) {
      font-size: .8rem; } } }


.catering-navbar__end-container {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 1em; }

.catering-navbar__burger-blip {
  @include no-touchie;

  position: absolute;
  top: calc(50% - 10px + -0.8em);
  right: calc(50% - 10px + -0.8em);
  width: 1.6em;
  height: 1.6em;

  background-color: $primary;
  color: $primary-invert;
  border-radius: 1em;
  overflow: hidden;

  font-size: 0.6rem;
  font-weight: bold;
  line-height: 1.6em;
  // padding-top: 1px
  text-align: center;

  opacity: 1;
  transition: font-size .2s, opacity .2s; }

.catering-navbar__burger.is-active .catering-navbar__burger-blip {
  font-size: 0;
  opacity: 0; }

.navbar-burger {
  background: transparent;
  border: 0;
  outline: 0;

  &:hover {
    background-color: $burger-hover; }

  span {
    height: 4px;
    left: calc(50% - 13px);
    width: 26px;

    &:nth-child(1) {
      top: calc(50% - 10px); }

    &:nth-child(2) {
      top: calc(50% - 2px); }

    &:nth-child(3) {
      top: calc(50% + 6px); } }

  &.is-active {
    span {
      &:nth-child(1) {
        transform: translateY(7.7px) rotate(45deg); }

      &:nth-child(3) {
        transform: translateY(-7.7px) rotate(-45deg); } } } }
