.order-preview {
  background-color: #f7f0e8; }

// .order-preview__ios-bar
//   height: 20rem

//   @media (min-width: $tablet)
//     height: 0

.order-preview__head {
  padding: 1rem; }

.order-preview__head-title {
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 0.5rem; }

.order-preview__header-row {
  font-size: 1.5rem;
  font-weight: bold;
  border-bottom: 1px solid #808080;

  &, &:not(:last-child) {
    margin-bottom: 0.5rem; } }

.order-preview__item-spacer {
  padding: 0; }

.order-preview__item-title-row {
  font-size: 1.5rem;
  font-weight: bold;

  scroll-snap-align: start;

  &, &:not(:last-child) {
    margin-bottom: 0; } }

.order-preview__item-title {
  flex-shrink: 1; }

// .order-preview__item-quantity
//   width: 5.2rem

// .order-preview__item-quantity-input
//   font-weight: bold
//   text-align: right

.order-preview__item-description {
  white-space: pre-wrap;
  font-size: 0.7rem; }

.order-preview__item-price {
  text-align: right;
  font-weight: bold; }

.order-preview__item-tooltip {
  text-align: right; }

.order-preview__item {
  &:not(:last-child) {
    margin-bottom: 1.5rem; } }

.order-preview__tooltip {
  text-align: right;
  margin-bottom: 1em; }

.order-preview__foot {
  border-top: 1px solid #808080;
  padding: 1rem;

  scroll-snap-align: end; }

.order-preview__tax-row {
  font-weight: bold;
  &, &:not(:last-child) {
    margin-bottom: 0; } }

.order-preview__total-row {
  font-weight: bold;
  font-size: 1.5rem;

  &, &:not(:last-child) {
    margin-bottom: 0; } }

.order-preview__detail {
  overflow: hidden;
  max-width: calc(100vw - 2rem);

  &, &:not(:last-child) {
    margin-bottom: 0; }

  .level-right {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 1em;
    min-width: 0;
    flex-shrink: 1; } }

.order-preview__payment-box {
  margin-top: 1em;

  @media (max-width: 768px) { // ugly fix for "$mobile not found" (why the hell is it not found?)
    margin-bottom: 12em; } }

.order-preview__payment-form {
  margin: 0 auto;

  border-radius: 0.5rem;
  border: 1px solid $border;
  box-sizing: content-box;
  overflow: hidden;

  & > div {
    &, & > iframe {
      height: 100%; } } }

.order-preview__thank-you-wrapper {
  margin: 1.5rem 0; }

.order-preview__thank-you-message {
  font-size: 2rem;
  text-align: center; }

.order-preview__thank-you-icon {
  margin: 1rem 0; }

.order-preview__thank-you-long {
  font-size: 1rem;
  color: $text;

  max-width: 25em;
  margin: 0 auto;

  @media (min-width: $tablet) {
    font-size: 1.25rem; } }

.order-preview__back-button {
  margin: 1em auto; }

.order-preview__credit-card-loading--hidden {
  display: none; }

.order-preview__checkout-button {
  &:not(:last-child) {
    margin-bottom: 0.5em; } }

.order-preview__special-request-modal {
  padding: 4rem 1rem 0;
  justify-content: flex-start; }

.order-preview__back-to-menu-button {
  @media (min-width: $tablet) {
    visibility: hidden; } }
