.order-layout__hero-body {
  padding: 0; }

.order-layout__columns {
  // width: 100%
  // height: 100%
  flex: 1 1 100%;

  &, &:last-child {
    margin: 0; } }

.order-layout__main-column {
  min-height: 100vh;
  // padding: 0

  scroll-snap-align: start;

  // @media screen and ( min-width: $tablet )
  display: flex;
  flex-direction: column;
  height: 100vh;

  @media (min-width: $tablet) {
    flex: 5 5 1px; } }

.order-layout__main-content {
  // @media screen and ( min-width: $tablet )
  overflow-y: auto;
  padding-top: 1.5em; }

.order-layout__title-section {
  padding: 1em;
  background-color: $text;
  color: $background; }

.order-layout__order-column {
  border-top: 1px solid #808080;
  max-height: 100vh;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  // padding: 0

  background-color: #f7f0e8;

  scroll-snap-align: end;

  @media (min-width: $tablet) {
    border-top: none;
    border-left: 1px solid #808080;

    height: 100vh;
    overflow-y: auto;

    flex: 1 1 316px; } }
