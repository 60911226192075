.home-page__section {
  padding-top: 0; }

.home-page__basic-form {
  max-width: 30rem; }

.home-page__select-field {
  display: flex;
  justify-content: space-between; }

.home-page__title-button {
  margin-left: 0.5em; }

.home-page__title-button-inner {
  vertical-align: baseline; }

.home-page__menu-card {
  // border: 1px solid $border
  border-radius: $radius; }

.home-page__menu-card-image {
  border-radius: 0; }

.home-page__foot {
  text-align: center;
  font-size: .75em;
  margin: .75rem 0;
  padding: .5rem .25rem;
  scroll-snap-align: start;

  color: mix($dark, $light, $weight: 60%);

  a {
    color: mix($link, $light, $weight: 60%); }

  position: relative;

  &::after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    height: 1px;
    background-color: mix($dark, $light, $weight: 20%); } }
