.catering-cart-wrapper {
  .container {
    padding: 0 1.5rem 1rem; } }

.catering-tip-input {
  font-size: .75rem;
  position: relative;

  background-color: $white;

  input {
    background-color: transparent;
    padding-left: 15px; } }

.DayPickerInput-Overlay {
  z-index: 5 !important; }

.tip-amount {
  max-width: 88px; }

@media screen and (max-height: 575px) {
  #g-recaptcha {
    max-width: 270px;
    transform: scale(.89);
    -webkit-transform: scale(.89);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0; } }


.catering-preview {
  &.order-preview__item {
    &:not(:last-child) {
      margin-bottom: 3rem; } }

  .level {
    &.order-preview__item-title-row {
      align-items: flex-end; } } }
