.hero-head {
  &.title-variant {
    background-color: #f7f0e8; } }


.title-bar {
  // border-bottom: 2px solid #808080
  padding-bottom: 2px;
  position: relative;
  flex-shrink: 0;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;

  background-color: #f7f0e8;

  &, &:not(:last-child) {
    margin-bottom: 0; }

  &::after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: #dbdbdb; } }

.title-bar__left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem; }

.title-bar__image {
  max-height: 3rem; }

.title-bar__cart-button-container {
  margin: 0.5rem;

  @media screen and ( min-width: $tablet ) {
    display: none; } }

.title-bar__cart-button-blip {
  position: absolute;
  top: -0.8em;
  right: -0.8em;
  width: 1.6em;
  height: 1.6em;

  background-color: $primary;
  color: $primary-invert;
  border-radius: 1em;

  font-size: 0.5em;
  line-height: 1.6em;
  padding-top: 1px; }

.title-bar__title {
  padding: 0 1rem; }
