.menu-modal__image {
  border: 1px solid $border;

  max-width: 12rem;
  display: block;
  margin: auto;

  @media screen and ( min-width: $tablet ) {
    max-width: 18rem; }

  @media screen and ( min-width: $widescreen ) {
    max-width: 30rem; }

  @media screen and ( max-height: $tablet ) {
    max-width: 18rem; }

  @media screen and ( max-height: 600px ) {
    max-width: 12rem; }

  @media screen and ( max-height: 500px ) {
    max-width: 10rem; } }

.menu-modal__title {
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 0.5em; }

.menu-modal__description {
  font-size: 0.9em; }

.menu-modal__price {
  font-weight: bold;
  text-align: right; }

.menu-modal__level {
  margin-top: 1em; }

.menu-modal__container-selections {
  display: flex;
  justify-content: space-around;
  border: 1px solid $border;
  border-radius: 0.5em;
  padding: 1em 0;
  margin: 1em 0;
  text-align: center; }

.menu-modal__container-selection-title {
  font-size: 1.25em; }

.menu-modal__container-selection-price {
  font-weight: bold; }

.menu-modal__container-selection-button {
  margin-top: 0.5em; }

.modal-container .modal-container__modal {
  background-color: #f7f0e8; }
