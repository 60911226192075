.catering-page {
  height: 100vh;
  overflow-y: scroll;
  p {
    margin: 1em 0; } }

.catering-page__foot {
  text-align: center;
  font-size: 0.75em;
  margin: 0.5rem 0;
  padding: .5rem .25rem;

  color: mix($dark, $light, $weight: 60%);
  a {
    color: mix($link, $light, $weight: 60%); }

  position: relative;

  &::after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    height: 1px;
    background-color: mix($dark, $light, $weight: 20%); } }
