.menu-item {
  width: 7rem;
  margin: 0 auto;
  padding-bottom: 1rem; }

.menu-item__name {
  font-weight: bold;
  height: 4.5em;
  text-overflow: ellipsis;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center; }

.menu-item__cart-icon {
  font-size: 0.8rem; }
