.catering-item__in-cart-indicator-and-remover-wrapper {
  position: relative; }

.catering-item__in-cart-indicator-and-remover-blip {
  @include no-touchie;

  align-items: center;
  background-color: white;
  border-radius: 50%;
  color: #d44232;
  display: flex;
  font-size: 15.5px;
  height: 15.5px;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  width: 15.5px; }
