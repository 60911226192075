.icon-card__image {
  width: 7rem;
  height: 7rem;
  background-size: cover;
  background-position: center;
  margin: 1rem auto; }

.icon-card__image--hidden {
  display: none; }

.icon-card__image-wrapper--polaroid {
  border: 1px solid $grey-lighter;
  width: 7.9em;
  height: 9.5em;
  padding-top: 0.45em;
  margin: 0 auto 0.5em;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }

.icon-card__image--polaroid {
  margin: 0; }
