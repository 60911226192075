.platter-page__section {
  padding-top: 0; }

.platter-page__title {
  font-size: 1.25em;
  text-align: center;
  font-weight: bold; }

.platter-page__button {
  font-size: 0.75rem;

  @media screen and ( min-width: $tablet ) {
    font-size: 1rem; } }

.platter-page__card {
  font-weight: bold;
  // border: 1px solid $border
  border-radius: $radius; }

.platter-page__card-content {
  width: 7rem;
  margin: 0 auto;
  padding-bottom: 1rem; }

.platter-page__card-image {
  position: relative;
  border-radius: 0; }

.platter-page__card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.platter-page__card-overlay--selected {
    background-color: change-color($primary, $alpha: 0.75);
    border: 2px solid $primary;

    color: $primary-invert;
    font-size: 2rem;

    &.platter-page__card-overlay--overflow {
      background-color: change-color($danger, $alpha: 0.75);
      border: 2px solid $danger; } } }

.platter-page__card-overflow {
  font-size: 0.6rem;
  line-height: 1.1;
  font-weight: bold; }

.platter-page__category-title {
  max-width: 42vw; }

