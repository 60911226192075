.blipped-button__container {
  position: relative;
  display: inline-block; }

.blipped-button__icon {
  font-size: 0.8em; }

.blipped-button__blip {
  @include no-touchie;

  position: absolute;
  top: -0.8em;
  right: -0.8em;
  width: 1.6em;
  height: 1.6em;

  background-color: $dark;
  color: $dark-invert;
  border-radius: 1em;
  overflow: hidden;

  font-size: 0.6em;
  font-weight: bold;
  line-height: 1.6em;
  // padding-top: 1px
  text-align: center; }

.blipped-button__button.is-dark + .blipped-button__blip {
  background-color: $dark-invert;
  color: $dark;
  border: 1px solid $dark;
  box-sizing: content-box; }
